import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent {

  @Input() timelineItems: any[] = [];
  @Input() timeLineHeaders: any[] = [];
}
