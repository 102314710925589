import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionComponent } from '../shared/components/subscription/subscription.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private modalService: NgbModal) { }

  showSubscriptionExpiredModal() {
    this.modalService.open(SubscriptionComponent, {
      centered: true,
      keyboard: false,
      windowClass: 'subscription-modal'
    });
  }
}