<div class="history-tl-container">
  <ul class="tl">
    <li class="tl-item" *ngFor="let item of timelineItems">
      <div class="timestamp">
        <ng-container *ngFor="let header of timeLineHeaders">
          <ng-container *ngIf="header.key === 'follow_date'">
            {{ item[header.key] }}
          </ng-container>
          <ng-container *ngIf="header.key === 'follow_time'">
            {{ item[header.key] }}
          </ng-container>
        </ng-container>
      </div>

      <div class="timeline-header" *ngFor="let header of timeLineHeaders">
        <!-- for label with show -->
        <ng-container *ngIf="header.key !== 'follow_date' && header.key !== 'follow_time'">
          <div class="item-title" *ngIf="header.key === 'staff_name'">{{ item[header.key] }}</div>
          <div class="item-detail" *ngIf="header.key === 'follow_type'">{{ item[header.key] }}</div>
          <div class="item-detail-preview" *ngIf="header.key === 'note'">{{ item[header.key] }}</div>
        </ng-container>
        
      </div>
    </li>
  </ul>
</div>
