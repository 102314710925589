<div>
    <div class="modal-header">
      <h4 class="modal-title">Washing Product Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="washingProductForm" (keydown)="onKeydown($event)">
        <div class="input-container">
          <label for="product_name">Product Name:</label>
            <input type="text" id="product_name" formControlName="product_name" class="form-control"  readonly/>
        </div>

        <div class="input-container">
          <label for="washermen_name">Washermen Name:</label>
          <input type="text" id="washermen_name" formControlName="washermen_name" class="form-control" readonly/>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="submitForm()">Return Product</button>
      <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
  </div>
  