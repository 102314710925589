import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-return-order-popup',
  templateUrl: './return-order-popup.component.html',
  styleUrls: ['./return-order-popup.component.scss']
})
export class ReturnOrderPopupComponent implements OnInit {
  orderForm: FormGroup;
  addonsForm: FormGroup;
  summaryForm: FormGroup;
  @Input() orderId: any;
  orderDetails: any = [];
  addonsDetails: any = [];
  isDefectedAllChecked: boolean;

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
    private loader: LoaderService,
    private toastr: ToastrService
  ) {
    this.orderForm = this.fb.group({});
    this.addonsForm = this.fb.group({});
    this.summaryForm = this.fb.group({
      defected_amt: [''],
      payment: ['', Validators.required], 
      return_deposit: ['']
    });
  }

  ngOnInit() {
    this.fetchOrderDetails();
  }

  fetchOrderDetails() {
    this.loader.showLoader();
    this.apiService.getOrderDetailsById(this.orderId).subscribe({
      next: (response: any) => {
        this.orderDetails = response.data.order;
        this.addonsDetails = response.data.addons;
        this.isDefectedAllChecked = this.initialCheckboxState();
        this.loader.hideLoader();
        this.initializeForm();
      },
      error: (error: any) => {
        this.toastr.error('Error fetching order details');
        this.loader.hideLoader();
      }
    });
  }

  initializeForm() {
    if (!this.orderDetails) return;

    let defectedAmount = 0; 
    this.orderDetails.order_products.forEach(product => {
      const productFormGroup = this.fb.group({
        return_date: product.return_date || new Date().toISOString().split('T')[0],
        defected: [product.products.is_defected || false],
        returned: [product.status == 'RETURNED' || false]
      });

      this.orderForm.addControl('product_' + product.id, productFormGroup);
    });

    this.addonsDetails.forEach((addons: any) => {
       const addonsFormGroup = this.fb.group({
        id:[addons?.id],
        quantity: [addons?.quantity],
        return_quantity: [addons?.returned_quantity]
       });
       this.addonsForm.addControl('addons_' + addons.id, addonsFormGroup);
    })

    this.orderDetails.payment_histories.forEach(payment => {
      if (payment.status === 'OrderDefectAmt') {
        defectedAmount += payment.amount;
      }
    });

    this.summaryForm.patchValue({
      defected_amt: defectedAmount,
    });
  }

  updateProductCheckbox(product: any, checked: boolean) {
    product.products.is_defected = checked;
    this.isDefectedAllChecked = this.orderDetails.order_products.some(product => product.products.is_defected);
  }

  initialCheckboxState(): boolean {
    if (!this.orderDetails) return false;
    return this.orderDetails.order_products.some(product => product.products.is_defected);
  }

  closeModal() {
    this.activeModal.close();
  }

  submitOrderForm() {
    if (!this.orderForm.valid || !this.summaryForm.valid) {
      this.orderForm.markAllAsTouched();
      this.summaryForm.markAllAsTouched();
      return;
    }

    if (this.orderDetails.status === 'RETURNED') {
      this.toastr.error('Order is already RETURNED');
      return;
    }

    const selectedProducts = this.orderDetails.order_products.filter(product => 
      this.orderForm.get('product_' + product.id)?.get('returned')?.value &&
      product.status !== 'RETURNED'
    );

    const returnProducts = selectedProducts.map(product => ({
      product_id: product.products.id,
      is_defected: product.products.is_defected || '',
      return_date: this.orderForm.get('product_' + product.id)?.get('return_date')?.value
    }));
    const returnProductsNames = selectedProducts.map(product => product.products.name);

    let addons = this.addonsForm.value;
    let convertedAddons = Object.keys(addons).map(key => {
      let obj = addons[key];
      return {
        id: obj.id,
        quantity: Number(obj.quantity),
        returned_quantity: Number(obj.return_quantity)
      };
    });

    const data = {
      order_id: this.orderDetails.id,
      return_products: returnProducts,
      addons: convertedAddons,
      return_products_name: returnProductsNames,
      payment: Number(this.summaryForm.value.payment),
      is_defected: this.isDefectedAllChecked, 
      return_deposite: Number(this.summaryForm.value.return_deposit)
    };
    if(this.isDefectedAllChecked) {
      data['defected_amt'] = Number(this.summaryForm.value.defected_amt)
    }
    this.loader.showLoader();
    this.apiService.returnOrderUpdate(data).subscribe({
      next: (response: any) => {
        this.loader.hideLoader();
        this.toastr.success(response.message);
        this.activeModal.close();
      },
      error: (error: any) => {
        this.loader.hideLoader();
        this.toastr.error(error.message);
        this.activeModal.close();
      }
    });
  }

  isRequiredError(form: FormGroup, controlName: string) {
    const control = form.get(controlName);
    return control && control.hasError('required') && control.touched;
  }

  onDateTimeClick(event:Event){
    const input=event.target as HTMLInputElement;
    input.showPicker();
  }
}
