import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AdminLoginService } from "src/app/services/admin-login.service";
import { ApiOrganizationService } from "src/app/services/api-organization.service";
import { LoginService } from "src/app/services/login.service";
import { OrganizationLoginService } from "src/app/services/organization-login.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  userName: string;
  userRole: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  isLoggedIn: boolean;
  role: string;
  constructor(public router: Router, private loginService : LoginService,  private adminLoginService: AdminLoginService, private organizationService: OrganizationLoginService) {
    if (JSON.parse(localStorage.getItem("user"))) {
      console.log("true");
    } else {
      console.log("NO");
    }
  }

  ngOnInit() {
    this.role = this.router.url.split('/')[1];
    if (this.role === 'store') {
      this.userName = JSON.parse(localStorage.getItem('staffName'));
      this.userRole = JSON.parse(localStorage.getItem('staffRole'));
    } else if (this.role === 'admin') {
      this.adminLoginService.adminName$.subscribe((name: string) => {
         this.userName = name;
      })
      this.userRole = JSON.parse(localStorage.getItem('adminRole'));
    } else if (this.role === 'organization') {
      this.organizationService.orgName$.subscribe((name: string) => {
        this.userName = name;
     })
      this.userRole = JSON.parse(localStorage.getItem('orgRole'));
    }
  }

  logoutFunc() {
    if (this.role === 'store') {
      this.loginService.logout(); 
    } else if (this.role === 'admin') {
      this.adminLoginService.logout(); 
    } else if (this.role === 'organization') {
      this.organizationService.logout();
    }
  }
  
}
