<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img class="img-fluid for-light" src="assets/images/logo/black-logo.png" alt="" width="100px"/>
          <img class="img-fluid for-dark" src="assets/images/logo/white-logo.png" alt="" width="100px" />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <!-- <app-swiper></app-swiper> -->
      <div class="custom-dropdown" #dropDown *ngIf="role === 'store'">
        <div (click)="toggleDropdown()" class="header-logo d-flex align-items-center justify-content-between">
          <input type="text" class="filter-input" [(ngModel)]="selectedVal" (input)="filterData()" (blur)="onFilterBlur()">
          <i class="fa fa-caret-down ms-2" aria-hidden="true"></i>
        </div>
        <div *ngIf="isDropdownOpen" class="dropdown-content">
           <div *ngFor="let item of filterDropdownData">
              <div class="py-2 text-start px-3 items" (click)="selectOption(item)">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 400.24 400.24" xml:space="preserve">
                  <g>
                    <path d="M385.361,156.213H14.879V330.12H0.953v21.333h398.334V330.12h-13.926V156.213z M210.445,178.12h59.35v28.651h-59.35V178.12   z M130.445,178.12h59.35v28.651h-59.35V178.12z M109.795,206.771h-59.35V178.12h59.35V206.771z M155.37,318.287h-30.5v-63.5h30.5   V318.287z M195.37,318.287h-30.5v-63.5h30.5V318.287z M235.37,318.287h-30.5v-63.5h30.5V318.287z M275.37,318.287h-30.5v-63.5h30.5   V318.287z M349.795,206.771h-59.35V178.12h59.35V206.771z"/>
                    <path d="M162.044,120.787V100.12h21.274V48.787H22.068v51.333h21.274v20.667H0v23.426h400.24v-23.426H162.044z M145.819,60.396   h16.225v4.894h-10.252v6.186h9.539v4.895h-9.539v7.266h10.252v4.931h-16.225V60.396z M119.769,60.396h8.208   c3.827,0,6.661,0.696,8.497,2.09c1.838,1.396,2.757,3.511,2.757,6.351c0,1.656-0.457,3.131-1.37,4.422   c-0.912,1.29-2.202,2.303-3.872,3.034c4.237,6.333,7.002,10.424,8.285,12.273h-6.627l-6.726-10.81h-3.181v10.81h-5.972V60.396z    M90.586,63.661c2.317-2.472,5.642-3.708,9.972-3.708c4.328,0,7.638,1.245,9.932,3.738c2.293,2.493,3.439,6.076,3.439,10.752   c0,4.663-1.156,8.247-3.468,10.752c-2.312,2.505-5.626,3.758-9.942,3.758c-4.317,0-7.63-1.253-9.942-3.758   c-2.312-2.505-3.469-6.102-3.469-10.792C87.107,69.717,88.268,66.134,90.586,63.661z M62.752,60.396h21.272v4.971h-7.649v23.199   H70.4V65.367h-7.648V60.396z M42.037,87.258v-5.551c1.901,0.85,3.51,1.446,4.827,1.792c1.317,0.348,2.521,0.521,3.612,0.521   c1.311,0,2.315-0.251,3.016-0.752c0.701-0.501,1.051-1.246,1.051-2.234c0-0.553-0.155-1.043-0.463-1.475   c-0.308-0.429-0.761-0.845-1.358-1.242c-0.598-0.398-1.815-1.034-3.652-1.907c-1.723-0.811-3.012-1.587-3.874-2.332   c-0.86-0.745-1.548-1.612-2.061-2.602c-0.515-0.989-0.772-2.145-0.772-3.469c0-2.491,0.846-4.45,2.534-5.877   c1.691-1.426,4.025-2.139,7.005-2.139c1.464,0,2.861,0.173,4.19,0.521c1.33,0.346,2.72,0.835,4.172,1.463l-1.928,4.645   c-1.503-0.616-2.745-1.047-3.727-1.292c-0.984-0.243-1.95-0.365-2.901-0.365c-1.132,0-1.998,0.264-2.602,0.79   c-0.603,0.527-0.905,1.214-0.905,2.062c0,0.528,0.121,0.986,0.366,1.378c0.244,0.392,0.633,0.771,1.166,1.137   c0.532,0.366,1.795,1.024,3.785,1.976c2.634,1.259,4.438,2.521,5.416,3.786c0.977,1.266,1.465,2.815,1.465,4.653   c0,2.543-0.916,4.548-2.746,6.012c-1.831,1.466-4.377,2.197-7.641,2.197C47.008,88.953,44.348,88.387,42.037,87.258z    M153.864,120.787H51.522V100.12h102.342V120.787z"/>
                    <path d="M131.849,71.956c0.899-0.627,1.35-1.616,1.35-2.964c0-1.338-0.46-2.289-1.378-2.854c-0.917-0.566-2.342-0.849-4.268-0.849   h-1.813v7.612h1.928C129.558,72.902,130.951,72.588,131.849,71.956z"/>
                    <path d="M100.519,83.943c4.766,0,7.148-3.168,7.148-9.5c0-6.347-2.371-9.52-7.109-9.52c-2.377,0-4.17,0.8-5.378,2.399   c-1.206,1.601-1.81,3.974-1.81,7.12c0,3.146,0.597,5.517,1.792,7.111C96.355,83.146,98.141,83.943,100.519,83.943z"/>
                  </g>
                  </svg>
                <span class="ps-4">{{ item.store_name | titlecase }}</span>
              </div>
           </div>
        </div>
      </div>
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li class="language-nav d-none" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li>
        <!-- notification code -->
        <!-- <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li> -->
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
