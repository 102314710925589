import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-view-history-popup',
  templateUrl: './view-history-popup.component.html',
  styleUrls: ['./view-history-popup.component.scss']
})
export class ViewHistoryPopupComponent {
  leadHistory: any[] = []; 
  @Input() leadId: any;

  constructor(private activeModal: NgbActiveModal, private toastr: ToastrService, private loader: LoaderService, private apiService: ApiService) {}

  ngOnInit() {
    this.fetchLeadHistory();
  }

  closeModal() {
    this.activeModal.close();
  }

  timeLineHeaders = [
    { key: 'follow_date', label: 'Date' },
    { key: 'follow_time', label: 'Time' },
    { key: 'staff_name', label: 'Staff Name' },
    { key: 'follow_type', label: 'Type' },
    { key: 'note', label: 'Note' },
  ];

  fetchLeadHistory(): void {
    this.loader.showLoader();

    this.apiService.leadHistoryList( this.leadId ).subscribe(
      (response: any) => {

        if (response.data.leadsHistory) {
          this.loader.hideLoader();
          const leadsHistory = response.data.leadsHistory || {} ;

          this.leadHistory = leadsHistory.map((historyItem: any) => ({
            follow_date: historyItem.follow_date,
            follow_time: historyItem.follow_time,
            follow_type: this.getFollowTypeLabel(historyItem.follow_type),
            note: historyItem.note,
            staff_name: historyItem.staffs.name,
          }));
          this.toastr.success(response.message, '', { timeOut: 2000 });
          } else {
          this.toastr.error('Failed to fetch lead history', '' ,{ timeOut: 2000 });
          this.loader.hideLoader();
        }
      },
      (error: any) => {
        this.toastr.error('Error fetching lead history');
      }
    );
  }

  getFollowTypeLabel(type: string): string {
    switch (type) {
      case 'call':
        return 'Via Call';
      case 'message':
        return 'Via message';
      default:
        return type;
    }
  }
}
