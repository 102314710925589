import { Routes } from "@angular/router";
import { AdminAuthGuard } from "src/app/guards/admin-auth.guard";
import { AuthGuard } from "src/app/guards/auth.guard";
import { OrganizationAuthGuard } from "src/app/guards/organization-auth.guard";

export const content: Routes = [
  {
    path: "store",
    canActivate: [AuthGuard],
    loadChildren: () => import("../../components/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "admin",
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('../../components/super-admin/super-admin.module').then((m) => m.SuperAdminModule) 
  },
  {
    path: "organization",
    canActivate: [OrganizationAuthGuard],
    loadChildren: () => import('../../components/organization/organization.module').then((m) => m.OrganizationModule) 
  }
];
