import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderService } from '../shared/services/loader.service';
import { ApiService } from './api.service';
import { ToastrService } from 'ngx-toastr';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ApiOrganizationService } from './api-organization.service';
import { OrganizationLoginService } from './organization-login.service';
import { ApiAdminService } from './api-admin.service';
import { AdminLoginService } from './admin-login.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  selectedRole$ =  new BehaviorSubject<string>('Super admin');
  authStateSubscription: Subscription;
  roleType: string;
  constructor(private router: Router, private loaderService : LoaderService, private apiService: ApiService, private toastr: ToastrService, private authService: SocialAuthService, private apiOrganizationService: ApiOrganizationService, private apiAdminService: ApiAdminService, private orgLoginService: OrganizationLoginService, private adminLoginService: AdminLoginService) {}
  isLoggedIn$ = this.isAuthenticatedSubject.asObservable();
  googleUser: any;
  login(username: string, password: string): void {
    let data = {
      user_name: username,
      password: password
    }
    this.loaderService.showLoader();
    this.apiService.login(data).subscribe({
      next: (res: any) => {
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('pages', JSON.stringify(res.data.page));
        localStorage.setItem('staffName', JSON.stringify(res.data?.name));
        localStorage.setItem('staffRole', JSON.stringify(res.data?.role));
        localStorage.setItem('stores', JSON.stringify(res.data?.stores));
        localStorage.setItem('staff_id', JSON.stringify(res.data?.staff_id));
        localStorage.setItem('selected_store', JSON.stringify(res.data?.stores[0]));
        localStorage.setItem('expired_at', JSON.stringify(res.data?.stores[0].organizations.expired_at));
        localStorage.setItem('is_washing_added', JSON.stringify(res.data?.stores[0].organizations.organization_details?.is_washing_added));
        this.router.navigate(['/store/dashboard']);
        this.toastr.success(res.message);
        this.loaderService.hideLoader();
      }, error: (err: any) => {
        this.toastr.error('Please enter valid credentials');
        this.loaderService.hideLoader();
      }
    });
  }

  googleLogin() {
    // google email verify
    this.selectedRole$.subscribe((role:any) => {
       this.roleType = role;
    })
    this.authStateSubscription = this.authService.authState.subscribe((user) => {
      this.googleUser = user?.email;
      let requestBody = {
        email : this.googleUser
      }
      if(this.roleType === 'Staff'){
        this.staffEncryptLogin(requestBody);
        console.log('staff')
      } else if(this.roleType === 'Organization') {
        this.orgEncryptLogin(requestBody);
        console.log('inside org')
      }
    })
  }

  staffEncryptLogin(data: any) {
    this.loaderService.showLoader();
    this.apiService.encryptLogin(data).subscribe({
      next: (res: any) => {
        this.staffSocialLogin(res.data);
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Please check your credentials');
        } else {
          this.toastr.error('Store login failed');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  staffSocialLogin(data: any) {
    let requestBody = { data };
    this.apiService.socialLogin(requestBody).subscribe({
      next: (res: any) => {
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('staffName', JSON.stringify(res.data?.name));
        localStorage.setItem('staffRole', JSON.stringify(res.data?.role));
        localStorage.setItem('stores', JSON.stringify(res.data?.stores));
        localStorage.setItem('selected_store', JSON.stringify(res.data?.stores[0]));
        localStorage.setItem('staff_id', JSON.stringify(res.data?.staff_id));
        localStorage.setItem('pages', JSON.stringify(res.data.page));
        localStorage.setItem('expired_at', JSON.stringify(res.data?.stores[0].organizations.expired_at));
        localStorage.setItem('is_washing_added', JSON.stringify(res.data?.stores[0].organizations.organization_details.is_washing_added));
        this.router.navigate(['/store/dashboard']);
        this.toastr.success(res.message);
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Store not found. Please check your credentials.');
        } else {
          this.toastr.error('Store login failed. Please try again later.');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  orgEncryptLogin(data: any) {
    this.loaderService.showLoader();
    this.apiOrganizationService.encryptLogin(data).subscribe({
      next: (res: any) => {
        this.orgSocialLogin(res.data);
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Please check your credentials.');
        } else {
          this.toastr.error('Organization login failed');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  orgSocialLogin(data: any) {
    let requestBody = { data };
    this.apiOrganizationService.socialLogin(requestBody).subscribe({
      next: (res: any) => {
        localStorage.setItem('organizationToken', res.data.accessToken);
        localStorage.setItem('orgName', JSON.stringify(res.data?.name));
        localStorage.setItem('orgRole', JSON.stringify(res.data?.role));
        this.isAuthenticatedSubject.next(true);
        this.orgLoginService.orgName$.next(res.data?.name);
        this.router.navigate(['/organization/dashboard']);
        this.toastr.success(res.message);
        localStorage.setItem('expired_at', JSON.stringify(res.data?.expired_at));
        this.loaderService.hideLoader();
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Organization not found. Please check your credentials.');
        } else {
          this.toastr.error('Organization login failed. Please try again later.');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('pages');
    localStorage.removeItem('staffName');
    localStorage.removeItem('staffRole');
    localStorage.removeItem('stores');
    localStorage.removeItem('selected_store');
    localStorage.removeItem('page_id');
    localStorage.removeItem('staff_id');
    localStorage.removeItem('is_show');
    localStorage.removeItem('is_washing_added');
    localStorage.removeItem('expired_at');   
    this.router.navigate(['/login']);
    this.authService.signOut().then(() => {
      this.googleUser = null;
      this.authStateSubscription.unsubscribe();
    })
    .catch((error) => {});
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }
}
