import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent {
  constructor(public activeModal: NgbActiveModal) {}

  onDelete() {
    this.activeModal.close('delete'); 
  }

  onClose() {
    this.activeModal.dismiss('close');
  }
}
