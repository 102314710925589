<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Return Product</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="scrollable-card-container">
      <div *ngIf="orderDetails">
        <div *ngFor="let product of orderDetails.order_products">
          <div class="card mb-3 return-product-wrapper">
            <div class="card-header">
              <h6 class="card-title mb-0">Product Name: {{ product.products?.name }}</h6>
            </div>
            <div class="card-body">
              <form [formGroup]="orderForm.get('product_' + product.id)">
                <div class="static-section">
                  <div class="form-group">
                    <label for="return_date_{{product.id}}">Return Date:</label>
                    <input type="date" id="return_date_{{product.id}}" formControlName="return_date"
                      class="form-control" (click)="onDateTimeClick($event)"/>
                  </div>
  
                  <div class="form-group checkbox-contain">
                    <label class="mb-0 mt-2">Status:</label>
  
                    <div class="form-check form-check-inline checkbox checkbox-solid-primary">
                      <input type="checkbox" [id]="'defected_' + product.id" formControlName="defected"
                        class="form-check-input" (change)="updateProductCheckbox(product, $event.target.checked)">
                      <label class="form-check-label" [for]="'defected_' + product.id">Defected</label>
                    </div>
  
                    <div class="form-check form-check-inline checkbox checkbox-solid-primary">
                      <input type="checkbox" [id]="'return_' + product.id" formControlName="returned"
                        class="form-check-input">
                      <label class="form-check-label" [for]="'return_' + product.id">Return</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
      <div *ngIf="addonsDetails">
        <div *ngFor="let addons of addonsDetails">
          <div class="card mb-3 return-product-wrapper">
            <div class="card-header">
              <h6 class="card-title mb-0">Addons Name: {{ addons?.addon_name }}</h6>
            </div>
            <div class="card-body">
              <form [formGroup]="addonsForm.get('addons_' + addons.id)">
                <div class="static-section">
                  <div class="d-flex gap-3 justify-content-between">
                    <div class="form-group w-50">
                      <label for="quantity_{{addons.id}}">Quantity:</label>
                      <input type="number" id="quantity_{{addons.id}}" formControlName="quantity"
                        class="form-control">
                    </div>
                    <div class="form-group w-50">
                      <label for="return_quantity_{{addons.id}}">Return Quantity:</label>
                      <input type="number" id="return_quantity_{{addons.id}}" formControlName="return_quantity"
                        class="form-control">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="summaryForm" (ngSubmit)="submitOrderForm()" >
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="defected_checkbox">Is Defected?</label>
            <div class="form-check checkbox checkbox-solid-primary d-flex align-items-center is-defected">
              <input type="checkbox" id="defected_checkbox" class="form-check-input" [checked]="isDefectedAllChecked">
              <label class="form-check-label m-0 " for="defected_checkbox">Defected All</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="total_payment" class="mb-0">Total Payment:</label>
            <input type="number" id="total_payment" class="form-control" [value]="orderDetails?.total_rent" readonly>
          </div>
        </div>        

        <div class="col-md-4">
          <div class="form-group">
            <label for="remaining_payment" class="mb-0">Remaining Payment:</label>
            <input type="number" id="remaining_payment" class="form-control"
              [value]="orderDetails?.remaining_amount" readonly>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="total_deposit" class="mb-0">Total Deposit:</label>
            <input type="number" id="total_deposit" class="form-control" [value]="orderDetails?.total_deposite" readonly>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4" *ngIf="isDefectedAllChecked">
          <div class="form-group">
            <label for="defected_amt">Defected Amount:</label>
            <input type="number" id="defected_amt" formControlName="defected_amt" class="form-control" [value]="defectedAmount">
          </div>
        </div>        

        <div class="col-md-4">
          <div class="form-group">
            <label for="payment">Payment: <span class="text-danger">*</span></label>
            <input type="number" id="payment" formControlName="payment" class="form-control"
               [class.border-danger]="isRequiredError(summaryForm, 'payment')">
            <div class="text-danger" *ngIf="isRequiredError(summaryForm,'payment')">Payment is required</div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="return_deposit">Return Deposit:</label>
            <input type="number" id="return_deposit" formControlName="return_deposit" class="form-control">
          </div>
        </div>
      </div>

      <div class="align-items-end mt-4">
        <button type="submit" class="btn btn-primary">Submit</button>
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
      </div>
    </form>
  </div>
</div>
