<div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Return Buy Back Product</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="card mb-3 return-product-wrapper">
          <div class="card-header">
            <h6 class="card-title mb-0">Product Name: {{ productDetails?.product_name }}</h6>
          </div>
          <div class="card-body">
            <form [formGroup]="productForm">
             <div class="static-section">
                 <!-- Checkbox -->
                 <div class="form-group checkbox-contain">
                    <label class="mb-0">Status:</label>

                    <div class="form-check form-check-inline checkbox checkbox-solid-primary">
                    <input type="checkbox" id="defected" formControlName="is_defected" class="form-check-input" (change)="defectChange($event)">
                    <label class="form-check-label" for="defected">Defected</label>
                    </div>

                    <div class="form-check form-check-inline checkbox checkbox-solid-primary">
                    <input type="checkbox" id="return" formControlName="is_returned" class="form-check-input">
                    <label class="form-check-label" for="return">Return</label>
                    </div>
               </div>
               <div class="d-flex gap-3 justify-content-between mb-2">
                 <div class="form-group w-50">
                   <label for="" class='mb-0'>Payment Amount:</label>
                   <input type="number" id="" formControlName="payment" class="form-control">
                 </div>
                 <div class="form-group w-50">
                   <label for="" class='mb-0'>Return Deposit:</label>
                   <input type="number" id="" formControlName="return_amount"class="form-control" readonly>
                 </div>
               </div>
               <div class="form-group w-50"  *ngIf="is_defected">
                <label for="" class='mb-0'>Defected Amount:</label>
                <input type="number" id="" formControlName="defected_amount"class="form-control">
               </div>
             </div>
            </form>
          </div>
        </div>      
    </div>
    <div class="d-flex justify-content-end align-items-center gap-3 p-3 pt-0">
      <button type="submit" class="btn btn-primary" (click)="returnProduct()">Submit</button>
      <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
</div>
