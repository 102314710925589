import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  baseUrl = environment.apiUrl;
  private apiUrl = `${this.baseUrl}api/store-admin/auth/`;

  constructor(private http: HttpClient, private router: Router) {}

  login(data: any) {
    return this.http.post(`${this.apiUrl}login`, data)
  }
  
  encryptLogin(data: any) {
    return this.http.post(`${this.apiUrl}encrypt`, data);
  }

  socialLogin(data: any) {
    return this.http.post(`${this.apiUrl}social-login`, data);
  }

  getStaffList() {
    return this.http.get(`${this.apiUrl}staff/list`);
  }

  //add washermen module api's

  getAllWasherList() {
    return this.http.get(`${this.apiUrl}washermen/list`);
  }

  getAllWasherMenList(data: any) {
    return this.http.post(`${this.apiUrl}washermen/list`, data);
  }

  updateWasherMen(washerMenId: number, data: any) {
    return this.http.put(`${this.apiUrl}washermen/${washerMenId}`, data);
  }

  createWasherMen (data: any) {
    return this.http.post(`${this.apiUrl}washermen`, data);
  }

  deleteWasherMenStaff(storeId: number){
    return this.http.delete(`${this.apiUrl}washermen/${storeId}`);
  }

  //customer module api's
  getCustomerList() {
    return this.http.get(`${this.apiUrl}customer/list`);
  }

  getAllCustomerList(data: any) {
    return this.http.post(`${this.apiUrl}customer/list`, data);
  }

  updateCustomer(customerId: number, data: any) {
    return this.http.put(`${this.apiUrl}customer/${customerId}`, data);
  }

  createCustomer (data: any) {
    return this.http.post(`${this.apiUrl}customer`, data);
  }

  deleteCustomer(storeId: number){
    return this.http.delete(`${this.apiUrl}customer/${storeId}`);
  }

  //vendors module api's
  getVendorList() {
    return this.http.get(`${this.apiUrl}vendor/list`);
  }

  getAllVendorList(data: any) {
    return this.http.post(`${this.apiUrl}vendor/list`, data);
  }

  updateVendor(vendorId: number, data: any) {
    return this.http.put(`${this.apiUrl}vendor/${vendorId}`, data);
  }

  createVendor (data: any) {
    return this.http.post(`${this.apiUrl}vendor`, data);
  }

  deleteVendor(storeId: number){
    return this.http.delete(`${this.apiUrl}vendor/${storeId}`);
  }

  //Type module api's

  getTypeList() {
    return this.http.get(`${this.apiUrl}type/list`);
  }

  getAllTypeList(data: any) {
    return this.http.post(`${this.apiUrl}type/list`, data);
  }

  updateType(typeId: number, data: any) {
    return this.http.put(`${this.apiUrl}type/${typeId}`, data);
  }

  createType (data: any) {
    return this.http.post(`${this.apiUrl}type`, data);
  }

  deleteType(storeId: number){
    return this.http.delete(`${this.apiUrl}type/${storeId}`);
  }

  //income /expense module api’s
  getIncomeList() {
    return this.http.get(`${this.apiUrl}income/list`)
  }
  getExpenseList() {
    return this.http.get(`${this.apiUrl}expenses/list`)
  }
  
  getAllIncomeList(data: any) {
    return this.http.post(`${this.apiUrl}income/list`, data);
  }

  getAllExpenseList(data: any) {
    return this.http.post(`${this.apiUrl}expenses/list`, data);
  }

  updateIncomeExpense(incomeExpenseId: number, data: any) {
    return this.http.put(`${this.apiUrl}income-expenses/${incomeExpenseId}`, data);
  }

  createIncomeExpense(data: any) {
    return this.http.post(`${this.apiUrl}income-expenses`, data);
  }

  deleteIncomeExpense(incomeExpenseId: number){
    return this.http.delete(`${this.apiUrl}income-expenses/${incomeExpenseId}`);
  }

  // lead module api's
  getAllLeads() {
    return this.http.get(`${this.apiUrl}lead/list`);
  }

  getAllLeadList(data: any) {
    return this.http.post(`${this.apiUrl}lead/list`, data);
  }

  deleteLeads(leadId: number){
    return this.http.delete(`${this.apiUrl}lead/${leadId}`);
  }

  addLeads(data: any) {
    return this.http.post(`${this.apiUrl}lead`, data);
  }

  editLeads(leadId: number, data: any) {
    return this.http.put(`${this.apiUrl}lead/${leadId}`, data);
  }

  createFollowUp(data: any) {
    return this.http.post(`${this.apiUrl}lead/follow-up`,data);
  }

  leadHistoryList(leadId: number) {
    return this.http.get(`${this.apiUrl}lead/follow-up-history/list/${leadId}`);
  }

  // add New api's
  getParentCategoryList() { 
    return this.http.get(`${this.apiUrl}parent-category/list`);
  }

  getAllCategory() {
    return this.http.get(`${this.apiUrl}category/list`);
  }

  getAllCategoryList(data: any) {
    return this.http.post(`${this.apiUrl}category/list`, data);
  }

  addCategory(data: any) {
    return this.http.post(`${this.apiUrl}category`, data);
  }

  editCategory(categoryId: number, data: any) {
    return this.http.put(`${this.apiUrl}category/${categoryId}`, data);
  }

  deleteCategory(categoryId: number){
    return this.http.delete(`${this.apiUrl}category/${categoryId}`);
  }

  // product api's
  getAllProducts(name:any) {
    return this.http.post(`${this.apiUrl}product/list`,name);
  }

  getAllProductList(data: any) {
    return this.http.post(`${this.apiUrl}product/pagination/list`, data);
  }

  addProduct(data: any) {
    return this.http.post(`${this.apiUrl}product`, data);
  }

  editProduct(productId: number, data: any) {
    return this.http.put(`${this.apiUrl}product/${productId}`, data);
  }

  deleteProduct(productId: number){
    return this.http.delete(`${this.apiUrl}product/${productId}`);
  }

  // payment api's
  getAllPayment() {
    return this.http.get(`${this.apiUrl}payment-option/list`);
  }

  getAllPaymentList(data: any) {
    return this.http.post(`${this.apiUrl}payment-option/list`, data);
  }

  addPayment(data: any) {
    return this.http.post(`${this.apiUrl}payment-option`, data);
  }

  editPayment(paymentId: number, data: any) {
    return this.http.put(`${this.apiUrl}payment-option/${paymentId}`, data);
  }

  deletePayment(paymentId: number){
    return this.http.delete(`${this.apiUrl}payment-option/${paymentId}`);
  }
  // time-slot api's
  getAllTimeSlot() {
    return this.http.get(`${this.apiUrl}time-slot/list`);
  }

  getAllTimeSlotList(data: any) {
    return this.http.post(`${this.apiUrl}time-slot/list`, data);
  }

  addTimeSlot(data: any) {
    return this.http.post(`${this.apiUrl}time-slot`, data);
  }

  getTimeSlotById(timeId: number) {
    return this.http.get(`${this.apiUrl}time-slot/${timeId}`);
  }

  editTimeSlot(timeId: number, data: any) {
    return this.http.put(`${this.apiUrl}time-slot/${timeId}`, data);
  }

  deleteTimeSlot(timeId: number){
    return this.http.delete(`${this.apiUrl}time-slot/${timeId}`);
  }

  //dashBoard Api's
  getDashbaordList(data: any) {
    return this.http.post(`${this.apiUrl}dashboard`, data);
  }

  // book product Api's
  getAllOrderList(data: any) {
    return this.http.post(`${this.apiUrl}order/list`, data);
  }

  //order list Api's
  getAllOrder(data:any) {
    return this.http.post(`${this.apiUrl}order/today-list`, data);
  }

  todayReturnOrderList(data:any) {
    return this.http.post(`${this.apiUrl}today-return-order-list`, data);
  }

  getOrderDetailsById(orderId: number) {
    return this.http.get(`${this.apiUrl}order/${orderId}`);
  }  

  bookOrder(data: any) {
    return this.http.post(`${this.apiUrl}order`, data);
  }
  deleteOrder(id: any) {
    return this.http.delete(`${this.apiUrl}order/${id}`);
  }
  getOrderById(id: any) {
    return this.http.get(`${this.apiUrl}order/${id}`);
  }
  editOrder(id: any, data: any) {
    return this.http.put(`${this.apiUrl}order/${id}`, data);
  }

  //check availability Api's
  getAllAvailability(data: any) {
    return this.http.post(`${this.apiUrl}check-availability`, data);
  }

  //return order
  returnOrderUpdate(data: any) {
    return this.http.post(`${this.apiUrl}return-order`, data);
  }  

  //payment Api's
  pendingPayment(data: any){
    return this.http.post(`${this.apiUrl}pending-payment-list`, data);
  }

  //return product Api's
  getReturnProducts(data: any){
    return this.http.post(`${this.apiUrl}return-product-list`, data);
  }

  updateAssignToWasherman(data: any){
    return this.http.post(`${this.apiUrl}assign-to-washerman`, data);
  }

  //washing product Api's
  washingProduct(data: any){
    return this.http.post(`${this.apiUrl}product-in-wash/list`, data);
  }

  updateProductInWash(washProductId: any, data: any) {
    return this.http.post(`${this.apiUrl}return-from-washerman/${washProductId}`, data);
  }

  // addons API 
  getAllAddons() {
    return this.http.get(`${this.apiUrl}addons/list`);
  }

  getAllAddonsList(data: any) {
    return this.http.post(`${this.apiUrl}addons/list`, data);
  }

  addAddons(data: any) {
    return this.http.post(`${this.apiUrl}addons`, data);
  }

  editAddons(timeId: number, data: any) {
    return this.http.put(`${this.apiUrl}addons/${timeId}`, data);
  }

  deleteAddons(timeId: number){
    return this.http.delete(`${this.apiUrl}addons/${timeId}`);
  }

  // buy-back products API
  getAllBuyBackList(data: any) {
    return this.http.post(`${this.apiUrl}buy-back-product/list`, data);
  }

  addBuyBackProduct(data: any) {
    return this.http.post(`${this.apiUrl}buy-back-product`, data);
  }

  getBuyBackProductById(timeId: number) {
    return this.http.get(`${this.apiUrl}buy-back-product/${timeId}`);
  }

  editBuyBackProduct(timeId: any, data: any) {
    return this.http.put(`${this.apiUrl}buy-back-product/${timeId}`, data);
  }

  deleteBuyBackProduct(timeId: number){
    return this.http.delete(`${this.apiUrl}buy-back-product/${timeId}`);
  }
  returnProduct(data: any) {
    return this.http.post(`${this.apiUrl}return-buy-back-product`, data);
  }

  getInvoice(bookId: number, data: any) {
    return this.http.get(`${this.apiUrl}generate-invoice/${bookId}`, data);
  }
}
