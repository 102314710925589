import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { Error400Component } from "./shared/components/error400/error400.component";
import { AdminLoginComponent } from "./auth/login/admin-login.component";
import { OrganizationLoginComponent } from "./components/organization/organization-login/organization-login.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { 
    path: 'admin/login', 
    component: AdminLoginComponent 
  },
  {
    path: "",
    component: ContentComponent,
    children: content
  },
  {
    path: "",
    component: FullComponent,
    children: full
  },
  {
    path: "**",
    component: Error400Component
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
        useHash: true 
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
