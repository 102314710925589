<div class="modal-header">
    <h4 class="modal-title">Delete</h4>
    <button type="button" class="btn-close remove-button" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="mb-0">Are you sure want to delete?</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onClose()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="onDelete()">Delete</button>
</div>