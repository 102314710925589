import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";

// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { BasicTableComponent } from './components/basic-table/basic-table.component';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { Error400Component } from './components/error400/error400.component';
import { NgxPaginationModule } from "ngx-pagination";
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CardChartComponent } from './components/card-chart/card-chart.component';
import { TimeLineComponent } from './components/time-line/time-line.component';
import { DateFormatePipe } from './pipes/date-formate.pipe';
import { MobileValidatorDirective } from './directives/mobile-validator.directive';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    LanguagesComponent,
    NotificationComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SvgIconComponent,
    SwiperComponent,
    BasicTableComponent,
    DeletePopupComponent,
    Error400Component,
    WelcomeComponent,
    CardChartComponent,
    TimeLineComponent,
    DateFormatePipe,
    MobileValidatorDirective,
    ImageModalComponent,
    SubscriptionComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule, DragulaModule.forRoot(), TranslateModule.forRoot(), CarouselModule, SwiperModule, NgbPaginationModule, NgxPaginationModule],
  providers: [NavService, LayoutService, DecimalPipe, DatePipe],
  exports: [NgbModule, FormsModule, ReactiveFormsModule, TranslateModule, LoaderComponent, BreadcrumbComponent, FeatherIconsComponent, TapToTopComponent, SvgIconComponent, SwiperModule, BasicTableComponent, Error400Component, DeletePopupComponent, WelcomeComponent, CardChartComponent, TimeLineComponent, MobileValidatorDirective, DateFormatePipe, SubscriptionComponent],
})
export class SharedModule {}
