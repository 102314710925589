import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { OrganizationLoginService } from '../services/organization-login.service';
import { Router } from '@angular/router';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class OrganizationAuthInterceptor implements HttpInterceptor {

  constructor(private organizationLoginService: OrganizationLoginService, private router: Router, private toastr: ToastrService,private SubscriptionService: SubscriptionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const organizationToken = this.organizationLoginService.getOrganizationToken();
    let route = this.router.url.split('/')[1];
    let store_id = JSON.parse(localStorage.getItem('selected_store'))?.id || 3;
    store_id = store_id.toString();
    if (organizationToken && route === 'organization') {
      const isFormData = request.body instanceof FormData;
      let headers = new HttpHeaders({
        'Authorization': organizationToken,
        'store_id': store_id
      });

      if(isFormData) {
        headers = headers.delete('Content-Type');
      } else {
        headers = headers.set('Content-Type', 'application/json');
      }

      const clone = request.clone({ headers });
      return next.handle(clone).pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            this.organizationLoginService.logout();
            this.toastr.error('Organization not found. Please contact your administrator.');
          }else if (error.status === 404) {
            this.organizationLoginService.logout();
            this.toastr.error('Your session has expired. Please log in again.', 'Token Expired');
          }else if (error.status === 423) {
            this.SubscriptionService.showSubscriptionExpiredModal();
          }
          return throwError(() => error);
        })
      );
    }
    return next.handle(request);
  }

}
