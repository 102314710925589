<div class="container-fluid p-0" tabindex="0" (keydown)="handleKeyDown($event)">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
          </div>
          <div class="login-main">
            <div class="d-flex justify-content-end"><img src="assets/images/success.jpg" *ngIf="is_checked" class="check-icon" alt="" width="20" height="20"></div>
            <form class="theme-form" [formGroup]="loginForm" (keydown)="onKeyDown($event)">
              <h4 class="text-center">Sign in</h4>
              <div class="form-group">
                <label class="col-form-label" for="email">User Name</label>
                <input class="form-control" id="email" type="text" [ngClass]="{'border-danger': isError('email')}" placeholder="Username" formControlName="email" />
                <div class="text text-danger" *ngIf="isError('email') && loginForm.get('email').hasError('required')">Username is required</div>
              </div>
              <div class="form-group">
                <label class="col-form-label" for="password">Password</label>
                <input class="form-control" id="password" [type]="show ? 'text' : 'password'" formControlName="password" [ngClass]="{'border-danger': isError('password')}" placeholder="Password" />
                <div class="eyes-icon">
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div class="text text-danger" *ngIf="isError('password')">Password is required</div>
              </div>
              <div class="form-group role-container">
                <label class="col-form-label" for="role">Role</label>
                <div class="dropdown-select">
                  <select id="role" (change)="roleChange($event)" formControlName="role">
                    <option [value]="null" [selected]="true" disabled>Select Role</option>
                    <option *ngFor="let role of roleArray" [value]="role">{{ role }}</option>
                  </select>
                </div>
                <div class="text text-danger" *ngIf="isError('role') && loginForm.get('role').hasError('required')">Role is required</div>
              </div>
              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="button"><span>Sign in</span></button>
                </div>
              </div>
            </form>
            <div class="mt-2">
              <asl-google-signin-button
              type='standard'
              size='large'
              width="370"
              theme='outline'
              logo_alignment="center"
            ></asl-google-signin-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
