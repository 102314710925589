<div class="modal-overlay">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content full-screen-modal">
        <div class="modal-header">
          <h5 class="modal-title" id="subscriptionModalLabel">Subscription Expired</h5>
        </div>
        <div class="modal-body">
          <div class="expired-icon">&#9888;</div>
          <p>Your subscription has expired. Please refresh to check your subscription status.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"  (click)="refresh()">Refresh</button>
        </div>
      </div>
    </div>
</div>