import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { OrganizationLoginService } from "src/app/services/organization-login.service";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  pageType?:string;
  is_view?:boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router, private organizationLoginService: OrganizationLoginService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      icon: "stroke-home",
      type: "link",
      badgeType: "light-primary",
      active: false,
      path:'/store/dashboard',
      pageType: 'dashboard'
    },
    {
      title: "Lead",
      icon: "stroke-social",
      type: "link",
      badgeType: "light-primary",
      path:'/store/lead',
      pageType:'leads'
    },
    {
      title: "Add Member",
      icon: "stroke-user",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "store/add-washermen", title: "Washermen", type: "link", pageType:'washermans' },
        { path: "store/add-customer", title: "Customer", type: "link", pageType:'customers' },
        { path: "store/add-vendor", title: "Vendors", type: "link", pageType:'vendors' },
        // { path: "store/add-type", title: "Type", type: "link", pageType:'types' },
      ],
    },
    {
      title: "Add New",
      icon: "stroke-table",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "store/add-categories", title: "Categories", type: "link", pageType:'categories' },
        { path: "store/add-product", title: "Product", type: "link", pageType:'products' },
        { path: "store/add-payment", title: "Payment Options", type: "link", pageType:'payment_options' },
        { path: "store/time-slots", title: "Time Slots", type: "link", pageType:'time_slots' },
        { path: "store/addons", title: "Addons", type: "link", pageType:'addons' },
      ],
    },
    {
      title: "Book product",
      icon: "stroke-task",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "store/check-availability", title: "Check Availability", type: "link", pageType:'check_availabilities' },
        { path: "store/rent-order", title: "Rents Order", type: "link", pageType:'orders' }
      ],
    },
    {
      title: "Buy Back Product",
      icon: "cart",
      type: "link",
      badgeType: "light-primary",
      path:'/store/buy-back-product',
      pageType:'buy_back_products'
    },
    {
      title: "Income/Expense",
      icon: "expense",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "store/income", title: "Income", type: "link", pageType:'income' },
        { path: "store/expense", title: "Expense", type: "link", pageType:'expense' }
      ],
    },
    {
      title: "Report",
      icon: "stroke-calendar",
      type: "sub",
      badgeType: "light-primary",
      children: [
        { path: "store/today-order", title: "Today Order", type: "link", pageType:'today_orders' },
        { path: "store/today-return-order", title: "Today Return Order", type: "link", pageType:'today_return_orders' },
        { path: "store/return-product", title: "Return Product", type: "link", pageType:'return_products' },
        { path: "store/pending-payment", title: "pending Payment", type: "link", pageType:'pending_payments' },
        { path: "store/washing-product", title: "Washing Product", type: "link", pageType:'washing_products' },
      ],
    },
  ];

  ADMINMENUITEMS: Menu[] = [
    {
      title: "Admin Dashboard",
      icon: "stroke-home",
      type: "link",
      badgeType: "light-primary",
      active: false,
      path:'/admin/dashboard',
      is_view: true
    },
    {
      title: "Organization",
      icon: "stroke-internationalization",
      type: "link",
      badgeType: "light-primary",
      path:'/admin/organization',
      is_view: true
    },
    {
      title: "Setting",
      icon: "stroke-icons",
      type: "sub",
      badgeType: "light-primary",
      is_view: true,
      children: [
        { path: "/admin/edit-profile", title: "Edit Profile", type: "link", is_view: true },
        { path: "/admin/change-password", title: "Change Password", type: "link", is_view: true },
      ],
    },
  ];

  ORGANIZATIONMENUITEMS: Menu[] = [
    {
      title: "Organization Dashboard",
      icon: "stroke-home",
      type: "link",
      badgeType: "light-primary",
      active: false,
      path:'/organization/dashboard',
      is_view: true
    },
    {
      title: "Category",
      icon: "stroke-widget",
      type: "link",
      badgeType: "light-primary",
      path:'/organization/category',
      is_view: true
    },    
    {
      title: "Store",
      icon: "cart",
      type: "link",
      badgeType: "light-primary",
      path:'/organization/store',
      is_view: true
    },
    {
      title: "Role",
      icon: "user-visitor",
      type: "link",
      badgeType: "light-primary",
      path:'/organization/role',
      is_view: true
    },
    {
      title: "Staff",
      icon: "customers",
      type: "link",
      badgeType: "light-primary",
      path:'/organization/staff',
      is_view: true
    },
    // {
    //   title: "Type",
    //   icon: "stroke-animation",
    //   type: "link",
    //   badgeType: "light-primary",
    //   path:'/organization/type',
    //   is_view: true
    // },
    {
      title: "Vendor",
      icon: "stroke-user",
      type: "link",
      badgeType: "light-primary",
      path:'/organization/vendor',
      is_view: true
    },
    {
      title: "Setting",
      icon: "stroke-icons",
      type: "sub",
      badgeType: "light-primary",
      is_view: true,
      children: [
        { path: "/organization/edit-profile", title: "Edit Profile", type: "link", is_view: true },
        { path: "/organization/change-password", title: "Change Password", type: "link", is_view: true },
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  admin_items = new BehaviorSubject<Menu[]>(this.ADMINMENUITEMS);
  organization_items = new BehaviorSubject<Menu[]>(this.ORGANIZATIONMENUITEMS);
}
