import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "src/app/services/login.service";
import { OrganizationLoginService } from "src/app/services/organization-login.service";
const Swal = require('sweetalert2')
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  isLoggedIn: boolean = false;
  selectedRole: any;
  roleArray = ['Organization', 'Staff']
  is_checked: string;
  constructor(private fb: FormBuilder, public router: Router, private loginService : LoginService,private organizationLoginService: OrganizationLoginService) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
      remember: [false],
      role: ["", [Validators.required]]
    });
    this.loginService.selectedRole$.subscribe((role) => {
      this.selectedRole = role;
      this.loginForm.get('role').setValue(role);
    })
  }

  ngOnInit() {
    const savedRole = localStorage.getItem('selectedRole');
    if (savedRole) {
      this.selectedRole = savedRole;
      this.loginService.selectedRole$.next(savedRole);
      this.loginForm.get('role').setValue(savedRole);
    }
    if(this.selectedRole === 'Staff') {
      if(this.loginService.isLoggedIn()) {
        this.router.navigate(['store/dashboard']);
      }
    } else if(this.selectedRole === 'Organization') {
      if(this.organizationLoginService.isOrganizationLoggedIn()) {
        this.router.navigate(['organization/dashboard']);
      }
    }
    // social login
    this.loginService.googleLogin();
  }

  login() {  
    if (this.loginForm.valid) {
      let userEmail = this.loginForm.value['email'];
      let userPassword = this.loginForm.value['password'];
      let selectedRole = this.loginForm.value['role'];
      // Save selected role in local storage
      localStorage.setItem('selectedRole', selectedRole); 
      if(selectedRole === 'Staff') {
        this.loginService.login(userEmail, userPassword);
      } else if(selectedRole === 'Organization') {
        this.organizationLoginService.login(userEmail, userPassword);
      }
    } 
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  isError(control) {
    const data = this.loginForm.get(control);
    return (data.touched || data.dirty) && data.invalid;
  }

  showPassword(){
    this.show = !this.show
  }

  roleChange($event) {
    const selectedRole = $event.target.value;
    this.loginService.selectedRole$.next(selectedRole);
    this.loginForm.get('role').setValue(selectedRole);
    // Update the selected role in local storage
    localStorage.setItem('selectedRole', selectedRole); 
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'F2') {
      localStorage.setItem('is_show', 'true');
      this.is_checked = localStorage.getItem('is_show');
    }
  }
}
